import ApiService from '@/services/core/api.service'

const CropFertilizerService = {
  async get (query) {
    return ApiService.get(`admin/crop-fertilizers?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/crop-fertilizers', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/crop-fertilizers/${payload.id}`, payload)
  }
}

export default CropFertilizerService
