import ApiService from '@/services/core/api.service'

const ListService = {
  async getRegions () {
    return ApiService.get('shared/list/regions')
  },

  async getProvinces (query = '') {
    return ApiService.get(`shared/list/provinces?${query}`)
  },

  async getUserProvinces (query = '') {
    return ApiService.get(`shared/list/user-provinces?${query}`)
  },

  async getMunicipalities (query = '') {
    return ApiService.get(`shared/list/municipalities?${query}`)
  },

  async getBarangays (query = '') {
    return ApiService.get(`shared/list/barangays?${query}`)
  },

  async getClusters () {
    return ApiService.get('shared/list/clusters')
  },

  async getAreas (query = '') {
    return ApiService.get(`shared/list/areas?${query}`)
  },

  async getDistributions () {
    return ApiService.get('shared/list/distributions')
  },

  async getSources () {
    return ApiService.get('shared/list/sources')
  },

  async getChannels () {
    return ApiService.get('shared/list/channels')
  },

  async getDispositionTypes (query = '') {
    return ApiService.get(`shared/list/disposition-types?${query}`)
  },

  async getDispositions (query = '') {
    return ApiService.get(`shared/list/dispositions?${query}`)
  },

  async getUnsuccessfulReasons () {
    return ApiService.get('shared/list/unsuccessful-reasons')
  },

  async getPromotions () {
    return ApiService.get('shared/list/promotions')
  },

  async getAgents () {
    return ApiService.get('shared/list/agents')
  },

  async getLeadAgents () {
    return ApiService.get('shared/list/lead-agents')
  },

  async getResignedLeadAgents () {
    return ApiService.get('shared/list/resigned-lead-agents')
  },

  async getUsers () {
    return ApiService.get('shared/list/users')
  },

  async getUserGroups () {
    return ApiService.get('shared/list/user-groups')
  },

  async getDialects () {
    return ApiService.get('shared/list/dialects')
  },

  async getRaisers () {
    return ApiService.get('shared/list/raisers')
  },

  async getPackages () {
    return ApiService.get('shared/list/packages')
  },

  async getDealers () {
    return ApiService.get('shared/list/dealers')
  },

  async getFieldSizes () {
    return ApiService.get('shared/list/crop-field-sizes')
  },

  async getCrops () {
    return ApiService.get('shared/list/crops')
  },

  async getClassifications (query = '') {
    return ApiService.get(`shared/list/crop-classifications?${query}`)
  },

  async getVarieties (query = '') {
    return ApiService.get(`shared/list/crop-varieties?${query}`)
  },

  async getHerbicides (query = '') {
    return ApiService.get(`shared/list/crop-herbicides?${query}`)
  },

  async getFungicides (query = '') {
    return ApiService.get(`shared/list/crop-fungicides?${query}`)
  },

  async getInsecticides (query = '') {
    return ApiService.get(`shared/list/crop-insecticides?${query}`)
  },

  async getFertilizers (query = '') {
    return ApiService.get(`shared/list/crop-fertilizers?${query}`)
  },

  async getModeOfCommunications () {
    return ApiService.get('shared/list/mode-communications')
  },

  async getModeOfPayments () {
    return ApiService.get('shared/list/mode-payments')
  },

  async getCustomFields () {
    return ApiService.get('shared/list/custom-fields')
  },

  async getVisitationPurposes () {
    return ApiService.get('shared/list/visitation-purposes')
  },

  async getPartners () {
    return ApiService.get('shared/list/partners')
  },

  async getActivities (query) {
    return ApiService.get(`shared/list/activities?${query}`)
  },

  async getBusinessRequirements (query = '') {
    return ApiService.get(`shared/list/business-requirements?${query}`)
  },

  async getMarketingPrograms () {
    return ApiService.get('shared/list/marketing-programs')
  },

  async getMarketingProgramRegistrars () {
    return ApiService.get('shared/list/marketing-program-registrars')
  },

  async getTicketCategories () {
    return ApiService.get('shared/list/ticket-categories')
  },

  async getProductCategories (query = '') {
    return ApiService.get(`shared/list/product-categories?${query}`)
  },

  async getProducts (query = '') {
    return ApiService.get(`shared/list/products?${query}`)
  }
}

export default ListService
