import ApiService from '@/services/core/api.service'

const AttachmentService = {
  async upload (payload) {
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return ApiService.post('dsm/activity/attachments/upload', payload, header)
  },

  async removeAllFile (filePath) {
    return ApiService.delete(`dsm/activity/attachments/remove-all-file?path=${filePath}`)
  },

  async removeFile (filePath, fileName) {
    return ApiService.delete(`dsm/activity/attachments/remove-file?path=${filePath}&name=${fileName}`)
  }
}

export default AttachmentService
