import ApiService from '@/services/core/api.service'

const Monitoring = {
  async getLGARawDAta (query) {
    return ApiService.get(`supervisor/lga-raw-data?${query}`)
  },

  async getLGATickets (query) {
    return ApiService.get(`supervisor/lga-tickets?${query}`)
  },

  async getCSORawData (query) {
    return ApiService.get(`supervisor/cso-raw-data?${query}`)
  }
}

export default Monitoring
