import ApiService from '@/services/core/api.service'

const ReportService = {
  async getFarmersByPlaces (query) {
    return ApiService.get(`admin/reports/farmers-by-places?${query}`)
  },

  async getItemReport1 (query) {
    return ApiService.get(`admin/reports/report-1?${query}`)
  },

  async getUserReport2 (query) {
    return ApiService.get(`admin/reports/report-2?${query}`)
  }
}

export default ReportService
