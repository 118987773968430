function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard'
  },
  {
    path: '/user/leads',
    component: view('Leads'),
    name: 'user.leads',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Leads',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/raw-data/mobile-number',
    component: view('MobileNumber'),
    name: 'user.raw-data.mobile-number',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data'
        },
        {
          text: 'With Mobile Number',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/raw-data/facebook',
    component: view('Facebook'),
    name: 'user.raw-data.facebook',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data'
        },
        {
          text: 'Facebook',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/activities/visits',
    component: view('visits/Base'),
    name: 'user.visits',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Activities'
        },
        {
          text: 'Visits',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/activities/call-requests',
    component: view('call-requests/Base'),
    name: 'user.call-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Activities'
        },
        {
          text: 'Call Requests',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
