import ApiService from '@/services/core/api.service'

const BigLandOwnerService = {
  async get (query) {
    return ApiService.get(`admin/big-land-owners?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/big-land-owners', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/big-land-owners/${payload.agent}`, payload)
  }
}

export default BigLandOwnerService
