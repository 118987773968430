function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/collection/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const CollectionRoutes = [
  {
    path: '/collection/dashboard',
    component: view('Dashboard'),
    name: 'collection.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/collection/new-accounts',
    component: view('NewAccounts'),
    name: 'collection.new-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'New Accounts',
          active: true
        }
      ]
    }
  }
]

export default CollectionRoutes
