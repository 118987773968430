function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/agent/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const AgentRoutes = [
  {
    path: '/agent/dashboard',
    component: view('Dashboard'),
    name: 'agent.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/raisers/pending-calls',
    component: view('PendingCalls'),
    name: 'agent.pending-calls',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Pending Calls',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/pending-data',
    component: view('PendingData'),
    name: 'agent.pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/invalid-pending-data',
    component: view('InvalidPendingData'),
    name: 'agent.invalid-pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Invalid Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/campaign-tracker',
    component: view('CampaignTracker'),
    name: 'agent.campaign-tracker',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Campaign Tracker',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/campaign-data',
    component: view('CampaignData'),
    name: 'agent.campaign-data',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Campaign Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/marketing-program-tracker',
    component: view('MarketingProgramTracker'),
    name: 'agent.marketing-program-tracker',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Marketing Program Tracker',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/master-data',
    component: view('MasterData'),
    name: 'agent.master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmers/visit-requests',
    component: view('VisitRequests'),
    name: 'agent.visit-requests',
    meta: {
      breadcrumb: [
        {
          text: 'Farmers'
        },
        {
          text: 'Visit Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/dealers/master-data',
    component: view('Dealers'),
    name: 'agent.dealers-master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Dealers'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/facebook/pending-raw-data',
    component: view('PendingRawData'),
    name: 'agent.pending-raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Facebook'
        },
        {
          text: 'Pending Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/facebook/pending-data',
    component: view('FacebookRaw'),
    name: 'agent.facebook-pending-data',
    meta: {
      breadcrumb: [
        {
          text: 'Facebook'
        },
        {
          text: 'Pending Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/facebook/leads',
    component: view('FacebookLeads'),
    name: 'agent.facebook-leads',
    meta: {
      breadcrumb: [
        {
          text: 'Facebook'
        },
        {
          text: 'Leads',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/utilities/sms',
    component: view('UtilitySMS'),
    name: 'agent.sms',
    meta: {
      breadcrumb: [
        {
          text: 'Utilities'
        },
        {
          text: 'SMS',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/call-logs',
    component: view('ReportFarmerCallLogs'),
    name: 'agent.farmer-report-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/connected-summaries',
    component: view('ReportFarmerConnectedSummaries'),
    name: 'agent.farmer-report-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/unsuccessful-summaries',
    component: view('ReportFarmerUnsuccessfulSummaries'),
    name: 'agent.farmer-report-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/promotion-summaries',
    component: view('ReportFarmerPromotionSummaries'),
    name: 'agent.farmer-report-promotion-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Promotion Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/sms-summaries',
    component: view('ReportFarmerSMSSummaries'),
    name: 'agent.farmer-report-sms-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'SMS Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/farmer-reports/satisfaction-surveys',
    component: view('ReportFarmerSatisfactionSurveys'),
    name: 'agent.farmer-report-satisfaction-surveys',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Satisfaction Surveys',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/visit-request-histories',
    component: view('ReportVisitRequestHistories'),
    name: 'agent.report-visit-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Visit Request Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/reports/call-request-histories',
    component: view('ReportCallRequestHistories'),
    name: 'agent.report-call-request-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Call Request Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/dealer-reports/call-logs',
    component: view('ReportDealerCallLogs'),
    name: 'agent.report-dealer-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/dealer-reports/connected-summaries',
    component: view('ReportDealerConnectedSummaries'),
    name: 'agent.report-dealer-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/agent/dealer-reports/unsuccessful-summaries',
    component: view('ReportDealerUnsuccessfulSummaries'),
    name: 'agent.report-dealer-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  }
]

export default AgentRoutes
