import ApiService from '@/services/core/api.service'

const CropClassificationService = {
  async get (query) {
    return ApiService.get(`admin/crop-classifications?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/crop-classifications', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/crop-classifications/${payload.id}`, payload)
  }
}

export default CropClassificationService
