import ApiService from '@/services/core/api.service'

const ReportService = {
  async getFarmerCallLogs (query) {
    return ApiService.get(`supervisor/farmer-reports/call-logs?${query}`)
  },

  async getFarmerCallSummaries (query) {
    return ApiService.get(`supervisor/farmer-reports/call-summaries?${query}`)
  },

  async getFarmerConnectedSummaries (query) {
    return ApiService.get(`supervisor/farmer-reports/connected-summaries?${query}`)
  },

  async getFarmerUnsuccessfulSummaries (query) {
    return ApiService.get(`supervisor/farmer-reports/unsuccessful-summaries?${query}`)
  },

  async getFarmerPromotionSummaries (query) {
    return ApiService.get(`supervisor/farmer-reports/promotion-summaries?${query}`)
  },

  async getDealerCallLogs (query) {
    return ApiService.get(`supervisor/dealer-reports/call-logs?${query}`)
  },

  async getDealerCallSummaries (query) {
    return ApiService.get(`supervisor/dealer-reports/call-summaries?${query}`)
  },

  async getDealerConnectedSummaries (query) {
    return ApiService.get(`supervisor/dealer-reports/connected-summaries?${query}`)
  },

  async getDealerUnsuccessfulSummaries (query) {
    return ApiService.get(`supervisor/dealer-reports/unsuccessful-summaries?${query}`)
  },

  async getSMSSummaries (query) {
    return ApiService.get(`supervisor/farmer-reports/sms-summaries?${query}`)
  },

  async getSatisfactionSurvey (query) {
    return ApiService.get(`supervisor/farmer-reports/satisfaction-surveys?${query}`)
  }
}

export default ReportService
