import ApiService from '@/services/core/api.service'

const DashboardService = {
  async getCampaigns (query = '') {
    return ApiService.get(`supervisor/dashboard/campaigns?${query}`)
  },

  async getMarketingPrograms (query = '') {
    return ApiService.get(`supervisor/dashboard/marketing-program-tracker?${query}`)
  }
}

export default DashboardService
