function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/users',
    component: view('Users'),
    name: 'admin.users',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/accounts',
    component: view('UserAccounts'),
    name: 'admin.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/lead-agents',
    component: view('LeadAgents'),
    name: 'admin.lead-agents',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Lead Agents',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/dsm-accounts',
    component: view('DSMAccounts'),
    name: 'admin.dsm-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'District Sales Manager Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/dealers',
    component: view('Dealers'),
    name: 'admin.dealers',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Dealers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/customers',
    component: view('Customers'),
    name: 'admin.customers',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Customers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/persons/big-land-owners',
    component: view('BigLandOwners'),
    name: 'admin.big-land-owners',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Big Land Owners',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/raw-data',
    component: view('RawData'),
    name: 'admin.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Persons'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/utilities/sms',
    component: view('UtilitySMS'),
    name: 'admin.sms',
    meta: {
      breadcrumb: [
        {
          text: 'Utilities'
        },
        {
          text: 'SMS',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/regions',
    component: view('Regions'),
    name: 'admin.regions',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Regions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/provinces',
    component: view('Provinces'),
    name: 'admin.provinces',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Provinces',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/municipalities',
    component: view('Municipalities'),
    name: 'admin.municipalities',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Municipalities',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/barangays',
    component: view('Barangays'),
    name: 'admin.barangays',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Barangays',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/clusters',
    component: view('Clusters'),
    name: 'admin.clusters',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Clusters',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/areas',
    component: view('Areas'),
    name: 'admin.areas',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Areas',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/places/distributions',
    component: view('Distributions'),
    name: 'admin.distributions',
    meta: {
      breadcrumb: [
        {
          text: 'Places'
        },
        {
          text: 'Distributions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/categories',
    component: view('Crops'),
    name: 'admin.categories',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/classifications',
    component: view('CropClassifications'),
    name: 'admin.classifications',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Classifications',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/varieties',
    component: view('CropVarieties'),
    name: 'admin.varieties',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Varieties',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/fertilizers',
    component: view('CropFertilizers'),
    name: 'admin.fertilizers',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Fertilizers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/herbicides',
    component: view('CropHerbicides'),
    name: 'admin.herbicides',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Herbicides',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/fungicides',
    component: view('CropFungicides'),
    name: 'admin.fungicides',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Fungicides',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/insecticides',
    component: view('CropInsecticides'),
    name: 'admin.insecticides',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Insecticides',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/crops/field-sizes',
    component: view('CropFieldSizes'),
    name: 'admin.field-sizes',
    meta: {
      breadcrumb: [
        {
          text: 'Crops'
        },
        {
          text: 'Field Sizes',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/mobile-prefixes',
    component: view('MobilePrefixes'),
    name: 'admin.mobile-prefixes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Mobile Prefixes',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/communications',
    component: view('ModeOfCommunications'),
    name: 'admin.communications',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Communications',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/payments',
    component: view('ModeOfPayments'),
    name: 'admin.payments',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Payments',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/dialects',
    component: view('Dialects'),
    name: 'admin.dialects',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Dialects',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/visitation-purposes',
    component: view('VisitationPurposes'),
    name: 'admin.visitation-purposes',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Visitation Purposes'
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/ticket-categories',
    component: view('TicketCategories'),
    name: 'admin.ticket-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Ticket Categories'
        }
      ]
    }
  },
  {
    path: '/admin/products/categories',
    component: view('ProductCategories'),
    name: 'admin.product-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Products'
        },
        {
          text: 'Categories'
        }
      ]
    }
  },
  {
    path: '/admin/products',
    component: view('Products'),
    name: 'admin.products',
    meta: {
      breadcrumb: [
        {
          text: 'Products'
        }
      ]
    }
  },
  {
    path: '/admin/farmer-details/export-by-group',
    component: view('ExportByGroup'),
    name: 'admin.export-by-group',
    meta: {
      breadcrumb: [
        {
          text: 'Customer Data'
        },
        {
          text: 'Export By Group',
          active: true
        }
      ]
    }
  }

]

export default adminRoutes
