import ApiService from '@/services/core/api.service'

const AttachmentService = {
  async uploadLogAttachment (payload) {
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return ApiService.post('user/attachments/upload-visitations', payload, header)
  },

  async deleteLogAttachment (payload) {
    return ApiService.post('user/attachments/delete-visitations', payload)
  },

  async deleteAllLogAttachment (payload) {
    return ApiService.post('user/attachments/delete-all-visitations', payload)
  },

  async uploadCustomerPhoto (payload) {
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return ApiService.post('user/attachments/customer-photos', payload, header)
  }
}

export default AttachmentService
