import ApiService from '@/services/core/api.service'

const StatisticsService = {
  async getGraphCRM (query = '') {
    return ApiService.get(`shared/statistics/crm-statistics?${query}`)
  },

  async getGraphCustomerInformation (query) {
    return ApiService.get(`shared/statistics/customer-information?${query}`)
  },

  async getGraphHerbicides (query = '') {
    return ApiService.get(`shared/statistics/graph-herbicide?${query}`)
  },

  async getGraphHerbicideDike (query = '') {
    return ApiService.get(`shared/statistics/graph-herbicide-dike?${query}`)
  },

  async getGraphFungicides (query = '') {
    return ApiService.get(`shared/statistics/graph-fungicides?${query}`)
  },

  async getGraphInsecticides (query = '') {
    return ApiService.get(`shared/statistics/graph-insecticides?${query}`)
  },

  async getGraphFertilizers (query = '') {
    return ApiService.get(`shared/statistics/graph-fertilizers?${query}`)
  },

  async getGraphVarieties (query = '') {
    return ApiService.get(`shared/statistics/graph-varieties?${query}`)
  },

  async getGraphCropPrices (query = '') {
    return ApiService.get(`shared/statistics/graph-crop-prices?${query}`)
  },

  async getGraphCrops (query = '') {
    return ApiService.get(`shared/statistics/graph-crops?${query}`)
  },

  async getGraphRaisers (query = '') {
    return ApiService.get(`shared/statistics/graph-raisers?${query}`)
  }
}

export default StatisticsService
