import ApiService from '@/services/core/api.service'

const ChannelService = {
  async get (query) {
    return ApiService.get(`supervisor/channels?${query}`)
  },

  async post (payload) {
    return ApiService.post('supervisor/channels', payload)
  },

  async put (payload) {
    return ApiService.put(`supervisor/channels/${payload.id}`, payload)
  }
}

export default ChannelService
