import ApiService from '@/services/core/api.service'

const ApprovalService = {
  async approveAccount (payload) {
    return ApiService.put(`agent/pending-accounts/approve/${payload.id}`, payload)
  },

  async disapproveAccount (payload) {
    return ApiService.put(`agent/pending-accounts/disapprove/${payload.id}`, payload)
  }
}

export default ApprovalService
