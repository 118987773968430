function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/manager/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const managerRoutes = [
  {
    path: '/manager/dashboard',
    component: view('GraphCustomerInformation'),
    name: 'manager.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/manager/crops',
    component: view('GraphCrop'),
    name: 'manager.crops',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Crops',
          active: true
        }
      ]
    }
  },
  {
    path: '/manager/varieties',
    component: view('GraphVariety'),
    name: 'manager.varieties',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Varieties',
          active: true
        }
      ]
    }
  },
  {
    path: '/manager/herbicides',
    component: view('GraphHerbicides'),
    name: 'manager.herbicides',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Herbicides',
          active: true
        }
      ]
    }
  },

  {
    path: '/manager/herbicides-dike',
    component: view('GraphHerbicideDike'),
    name: 'manager.herbicides-dike',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Herbicides (Dike)',
          active: true
        }
      ]
    }
  },

  {
    path: '/manager/fungicides',
    component: view('GraphFungicide'),
    name: 'manager.fungicides',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Fungicides',
          active: true
        }
      ]
    }
  },

  {
    path: '/manager/insecticides',
    component: view('GraphInsecticide'),
    name: 'manager.insecticides',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Insecticides',
          active: true
        }
      ]
    }
  },

  {
    path: '/manager/fertilizers',
    component: view('GraphFertilizer'),
    name: 'manager.fertilizers',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Fertilizers',
          active: true
        }
      ]
    }
  },
  {
    path: '/manager/crop-prices',
    component: view('GraphCropPrice'),
    name: 'manager.crop-prices',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Crop Prices',
          active: true
        }
      ]
    }
  },
  {
    path: '/manager/raisers',
    component: view('GraphRaiser'),
    name: 'manager.raisers',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raisers',
          active: true
        }
      ]
    }
  }

]

export default managerRoutes
