import ApiService from '@/services/core/api.service'

const VisitRequestMessageService = {
  async get (query) {
    return ApiService.get(`user/visit-requests/messages?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/visit-requests/messages', payload)
  }
}

export default VisitRequestMessageService
