import ApiService from '@/services/core/api.service'

const ModeOfCommunicationService = {
  async get (query) {
    return ApiService.get(`admin/mode-communications?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/mode-communications', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/mode-communications/${payload.id}`, payload)
  }
}

export default ModeOfCommunicationService
