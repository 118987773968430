import ApiService from '@/services/core/api.service'

const DealerService = {
  async get (query) {
    return ApiService.get(`dsm/dealers?${query}`)
  },

  async post (payload) {
    return ApiService.post('dsm/dealers', payload)
  },

  async put (payload) {
    return ApiService.put(`dsm/dealers/${payload.id}`, payload)
  }
}

export default DealerService
