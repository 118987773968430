import ApiService from '@/services/core/api.service'

const VisitRequestMesssageService = {
  async get (query) {
    return ApiService.get(`agent/visit-requests/messages?${query}`)
  },

  async post (payload) {
    return ApiService.post('agent/visit-requests/messages', payload)
  }
}

export default VisitRequestMesssageService
