import ApiService from '@/services/core/api.service'

const ModeOfPaymentService = {
  async get (query) {
    return ApiService.get(`admin/mode-payments?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/mode-payments', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/mode-payments/${payload.id}`, payload)
  }
}

export default ModeOfPaymentService
