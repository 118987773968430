function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/dsm/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const DSMRoutes = [
  {
    path: '/dsm/dashboard',
    component: view('Dashboard'),
    name: 'dsm.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/dsm/new-accounts',
    component: view('NewAccounts'),
    name: 'dsm.new-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'New Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/dsm/my-dealers',
    component: view('MyDealers'),
    name: 'dsm.my-dealers',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'My Dealers',
          active: true
        }
      ]
    }
  },
  {
    path: '/dsm/my-activities',
    component: view('MyActivities'),
    name: 'dsm.my-activities',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'My Activities',
          active: true
        }
      ]
    }
  }
]

export default DSMRoutes
