function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/supervisor/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const supervisorRoutes = [
  {
    path: '/supervisor/dashboard',
    component: view('Dashboard'),
    name: 'supervisor.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/lga-raw-data',
    component: view('LGARawData'),
    name: 'supervisor.lga-raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Monitoring'
        },
        {
          text: 'LGA Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/lga-tickets',
    component: view('LGATickets'),
    name: 'supervisor.lga-tickets',
    meta: {
      breadcrumb: [
        {
          text: 'Monitoring'
        },
        {
          text: 'LGA Tickets',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/cso-raw-data',
    component: view('CSORawData'),
    name: 'supervisor.cso-raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Monitoring'
        },
        {
          text: 'CSO Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/raw-data',
    component: view('RawData'),
    name: 'supervisor.raw-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Raw Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/campaign-data',
    component: view('CampaignData'),
    name: 'supervisor.campaign-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Campaign Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/master-data',
    component: view('MasterData'),
    name: 'supervisor.master-data',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Master Data',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/campaigns',
    component: view('Campaigns'),
    name: 'supervisor.campaigns',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Campaigns',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/channels',
    component: view('Channels'),
    name: 'supervisor.channels',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Channels',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/disposition-types',
    component: view('DispositionTypes'),
    name: 'supervisor.disposition-types',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Disposition Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/dispositions',
    component: view('Dispositions'),
    name: 'supervisor.dispositions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Dispositions',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/unsuccessful-reasons',
    component: view('UnsuccessfulReasons'),
    name: 'supervisor.unsuccessful-reasons',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Unsuccessful Reasons',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/promotions',
    component: view('Promotions'),
    name: 'supervisor.promotions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Promotions',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/custom-fields',
    component: view('CustomFields'),
    name: 'supervisor.custom-fields',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Custom Fields',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/agent-groups',
    component: view('AgentGroups'),
    name: 'supervisor.agent-groups',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Agent Groups',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/agents',
    component: view('Agents'),
    name: 'supervisor.agents',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Agents',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/maintenances/marketing-programs',
    component: view('MarketingPrograms'),
    name: 'supervisor.marketing-programs',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Marketing Programs',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/crm-statistics',
    component: view('GraphCRM'),
    name: 'supervisor.crm-statistics',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'CRM Statistics',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/customer-information',
    component: view('GraphCustomerInformation'),
    name: 'supervisor.customer-information',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Customer Information',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/crops',
    component: view('GraphCrops'),
    name: 'supervisor.graph-crops',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Crops',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/crop-varieties',
    component: view('GraphCropVarieties'),
    name: 'supervisor.graph-crop-varieties',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Crop Varieties',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/herbicides',
    component: view('GraphHerbicideOnes'),
    name: 'supervisor.graph-herbicide-ones',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Herbicides',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/herbicides-dike',
    component: view('GraphHerbicideTwos'),
    name: 'supervisor.graph-herbicide-twos',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Herbicides (Dike)',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/fungicides',
    component: view('GraphFungicides'),
    name: 'supervisor.graph-fungicides',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Fungicides',
          active: true
        }
      ]
    }
  },

  {
    path: '/supervisor/statistics/insecticides',
    component: view('GraphInsecticides'),
    name: 'supervisor.graph-insecticides',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Insecticides',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/fertilizers',
    component: view('GraphFertilizers'),
    name: 'supervisor.graph-fertilizers',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Fertilizers',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/crop-prices',
    component: view('GraphCropPrices'),
    name: 'supervisor.graph-crop-prices',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Crop Prices',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/statistics/raisers',
    component: view('GraphRaisers'),
    name: 'supervisor.graph-raisers',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Raisers',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/call-logs',
    component: view('ReportFarmerCallLogs'),
    name: 'supervisor.farmer-report-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/call-summaries',
    component: view('ReportFarmerCallSummaries'),
    name: 'supervisor.farmer-report-call-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Call Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/connected-summaries',
    component: view('ReportFarmerConnectedSummaries'),
    name: 'supervisor.farmer-report-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/unsuccessful-summaries',
    component: view('ReportFarmerUnsuccessfulSummaries'),
    name: 'supervisor.farmer-report-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/promotion-summaries',
    component: view('ReportFarmerPromotionSummaries'),
    name: 'supervisor.farmer-report-promotion-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Promotion Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/sms-summaries',
    component: view('ReportFarmerSMSSummaries'),
    name: 'supervisor.farmer-report-sms-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'SMS Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/farmer-reports/satisfaction-surveys',
    component: view('ReportFarmerSatisfactionSurveys'),
    name: 'supervisor.farmer-report-satisfaction-surveys',
    meta: {
      breadcrumb: [
        {
          text: 'Farmer Reports'
        },
        {
          text: 'Satisfaction Surveys',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/dealer-reports/call-logs',
    component: view('ReportDealerCallLogs'),
    name: 'supervisor.dealer-report-call-logs',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Call Logs',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/dealer-reports/call-summaries',
    component: view('ReportDealerCallSummaries'),
    name: 'supervisor.dealer-report-call-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Call Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/dealer-reports/connected-summaries',
    component: view('ReportDealerConnectedSummaries'),
    name: 'supervisor.dealer-report-connected-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Connected Summaries',
          active: true
        }
      ]
    }
  },
  {
    path: '/supervisor/dealer-reports/unsuccessful-summaries',
    component: view('ReportDealerUnsuccessfulSummaries'),
    name: 'supervisor.dealer-report-unsuccessful-summaries',
    meta: {
      breadcrumb: [
        {
          text: 'Dealer Reports'
        },
        {
          text: 'Unsuccessful Summaries',
          active: true
        }
      ]
    }
  }
]

export default supervisorRoutes
