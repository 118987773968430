import ApiService from '@/services/core/api.service'

const ClusterService = {
  async get (query) {
    return ApiService.get(`admin/clusters?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/clusters', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/clusters/${payload.id}`, payload)
  }
}

export default ClusterService
