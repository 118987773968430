
import Vue from 'vue'
import * as rules from 'vee-validate/dist/rules'
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import { SharedValidationService } from '@/services'

localize({
  en: {
    messages: {
      required: field => {
        return `The ${field} field is required`
      },

      required_if: field => {
        return `The ${field} field is required`
      },

      email: field => {
        return `The ${field} must be a valid email address`
      },

      double: field => {
        return `The ${field} must be a valid number`
      },

      max: (field, option) => {
        return `The ${field} must be atleast lesser than or equal to ${option.length} characters`
      },

      min: (field, option) => {
        return `The ${field} must be atleast greater than or equal to ${option.length} characters`
      },

      max_value: (field, option) => {
        return `The ${field} must be atleast lesser than or equal to ${option.max}`
      },

      min_value: (field, option) => {
        return `The ${field} must be atleast greater than or equal to ${option.min}`
      },

      confirmed: (field, option) => {
        return `The ${field} must be the same as the value of ${option.target}`
      }
    }
  }
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('unique', {
  validate: async (value, args) => {
    let ignoreId = 0

    if (args.length > 0) {
      ignoreId = parseInt(args[0])
    }

    if (value.length < 11) {
      return true
    }

    const response = await SharedValidationService.isMobileUnique({
      mobile_number: value,
      ignore_id: ignoreId
    })

    if (response.data.valid) {
      return true
    }

    return {
      valid: false
    }
  }
})

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

    return {
      valid: regex.test(value)
    }
  },
  message: 'The {_field_} field must contain only decimal values'
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
