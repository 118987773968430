import ApiService from '@/services/core/api.service'

const CropInsecticideService = {
  async get (query) {
    return ApiService.get(`admin/crop-insecticides?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/crop-insecticides', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/crop-insecticides/${payload.id}`, payload)
  }
}

export default CropInsecticideService
