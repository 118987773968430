import ApiService from '@/services/core/api.service'

const DSMAccountService = {
  async get (query) {
    return ApiService.get(`admin/dsm-accounts?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/dsm-accounts', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/dsm-accounts/${payload.id}`, payload)
  }
}

export default DSMAccountService
